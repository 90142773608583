import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { SecretsSharedWithMe } from './SecretsSharedWithMe';
import { SecretsSharedByMe } from './SecretsSharedByMe';
import { useSecretsShared } from './useSecretsShared';

export const SecretsShared = () => {
  const { labels, handleChangeTab, tab } = useSecretsShared();

  return (
    <TabContext value={tab}>
      <Paper
        sx={{
          marginTop: 2,
          marginLeft: 3,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <TabList value={tab} onChange={handleChangeTab} aria-label="secrets tabs">
          <Tab className="tab-secrets-shared-with-me" label={labels.tabs.secretsSharedWithMe} value="1" />
          <Tab className="tab-secrets-shared-by-me" label={labels.tabs.secretsSharedByMe} value="2" />
        </TabList>
        <TabPanel value="1">
          <SecretsSharedWithMe />
        </TabPanel>
        <TabPanel value="2">
          <SecretsSharedByMe />
        </TabPanel>
      </Paper>
    </TabContext>
  );
};

export default SecretsShared;
