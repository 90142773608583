import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { didUserActivateSession } from 'services';

export const useAuth = () => {
  const [activeUser, setActiveUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    didUserActivateSession().then((userFromSession) => {
      if (!userFromSession) {
        navigate('/login');
        setLoading(false);
        return;
      }

      setActiveUser(userFromSession);
      setLoading(false);
    });
  }, [navigate]);

  return {
    activeUser,
    isLoading,
  };
};

export default useAuth;
