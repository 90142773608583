import * as React from 'react';
import PropTypes from 'prop-types';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { LanguageContext } from 'context';
import { TooltipMenu } from './TooltipMenu';
import { LanguageSelector } from './LanguageSelector';
import { DarkModeSelector } from './DarkModeSelector';

const StyledAppBarContainer = (drawerWidth) =>
  styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

export const AppBar = ({ drawerWidth, position, open, toggleDrawer, notificationCount, avatarImgSrc, avatarName, tooltipMenuItems }) => {
  const AppBarContainer = StyledAppBarContainer(drawerWidth);

  const { lang } = React.useContext(LanguageContext);

  return (
    <AppBarContainer position={position} open={open}>
      <Toolbar
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flex: 'auto',
            ...(open && { display: 'none' }),
          }}
        >
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <img src="logo.png" alt="Secure Store logo" width={35} style={{ margin: '0 5px 0 36px' }} />
          <Typography color="white" sx={{ flex: 'auto', fontWeight: 500, fontSize: 20 }}>
            {lang?.siteTitle}
          </Typography>
        </Box>

        {notificationCount && (
          <IconButton color="inherit">
            <Badge badgeContent={notificationCount} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        )}
        <DarkModeSelector />
        <LanguageSelector />
        {(avatarImgSrc || avatarName) && (
          <TooltipMenu menuId="tooltip-menu" items={tooltipMenuItems || []} tooltipTitle={lang?.navBar.accountSettings}>
            <Avatar src={avatarImgSrc || ''}>{avatarName}</Avatar>
          </TooltipMenu>
        )}
      </Toolbar>
    </AppBarContainer>
  );
};

AppBar.defaultProps = {
  notificationCount: null,
  avatarName: null,
};

AppBar.propTypes = {
  drawerWidth: PropTypes.number.isRequired,
  position: PropTypes.string.isRequired,
  notificationCount: PropTypes.number,
  toggleDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tooltipMenuItems: PropTypes.instanceOf(Array).isRequired,
  avatarImgSrc: PropTypes.string.isRequired,
  avatarName: PropTypes.string,
};

export default AppBar;
