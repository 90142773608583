import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { FileUpload } from 'components/controls';

import { SecureStorePageLayout } from 'components/layout';
import { SnackbarAlert, useSnackbarAlert } from 'components/alert';
import { useUpdateUserKey } from './useUpdateUserKey';

export const UpdateUserKey = () => {
  const { info, publicKeyFile, publicKeyFileHandler, uploadUserPublicKey, error, alertText } = useUpdateUserKey();

  const { handleCloseSnackbar, handleOpenSnackbar, openSnackbar } = useSnackbarAlert();

  return (
    <SecureStorePageLayout>
      <SnackbarAlert
        handleCloseSnackbar={handleCloseSnackbar}
        openSnackbar={openSnackbar}
        snackbarText={alertText}
        type={error ? 'error' : 'success'}
        name="updateUserKey"
      />
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12} alignContent="center">
          <Grid item xs={12} mb={2}>
            <Alert severity="info" color="primary">
              <Typography>{info.infoText}</Typography>
            </Alert>
          </Grid>
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              height: '75vh',
              width: '100%',
            }}
          >
            <FileUpload
              selectFile={publicKeyFileHandler}
              selectedFile={publicKeyFile}
              selectFileLabel={info.selectedFileLabel}
              uploadButtonLabel={info.submitText}
              onSecondaryAction={async () => {
                await uploadUserPublicKey().then(() => handleOpenSnackbar());
              }}
              reportsProgress
              isInline
            />
          </Paper>
        </Grid>
      </Grid>
    </SecureStorePageLayout>
  );
};

export default UpdateUserKey;
