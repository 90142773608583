export const LANG_EN = {
  siteTitle: 'Secure Store',
  login: {
    buttonText: 'Log In',
  },
  logout: {
    buttonText: 'Log Out',
  },
  session: {
    title: 'Session Expired',
    text: 'Your session has expired. Please login again',
  },
  navBar: {
    language: 'Language',
    spanish: 'Spanish',
    english: 'English',
    accountSettings: 'Account Settings',
  },
  menu: {
    generateKeyPair: 'Generate Key Pair',
    shareSecrets: 'Share Secrets',
    updatePublicKey: 'Update Public Key',
  },
  generateKeyPair: {
    title: 'Generate key pair',
    info: 'Please keep your private key safe; you will use this to decrypt your secrets. If you lose your private key, you will lose access to your secrets!',
    description: 'Select a key length appropriate for your secret',
    KeyPairDescription: 'Enter a proper name for your key pair',
    keyPairName: 'Key pair name',
    advice: 'Tip: Longer key lengths are suitable for bigger secrets',
    adviceNameKeyPair: 'Tip: Use an easy name to remember',
    keyLength: 'Key Length',
    submit: 'Submit and download',
    privateKey: 'Private Key',
    publicKey: 'Public Key',
    successAlert: 'Key pair generated successfully',
    dialog: {
      title: 'Are you sure?',
      description:
        'Are you sure you want to save a new key pair? Remember that if you save new ones, you will not be able to decrypt previously sent or received secrets, only the new ones.',
      ok: "Yes, I'm sure",
      cancel: 'No, cancel',
    },
    infoDownloadKeyPair: 'Please, enable automatic downloads in your browser to download your key pair',
  },
  deleteSecrets: {
    dialog: {
      title: 'Are you sure?',
      description:
        "Are you sure you want to delete the selected secrets? You won't be able to see them anymore but the person who sent or received them can still see them.",
      ok: "Yes, I'm sure",
      cancel: 'No, cancel',
    },
    snackbarAlert: {
      description: 'Secrets deleted successfully',
    },
  },
  shareSecrets: {
    recipient: 'Recipient',
    secretName: 'Secret Name',
    secretValue: 'Secret Value',
    secretInfo: {
      title: 'Secret Info',
      recipientHelper: 'Enter the email address of who should receive the secret',
      recipientInfo: 'User must have public key saved in the application to be visible in this list',
      secretNameHelper: 'A unique name to identify your secret',
      secretValueHelper: 'The actual contents of the secret you want to send',
      addSecret: 'Add secret',
    },
    secretsReadyToShare: {
      title: 'Secrets Ready to Share',
      shareSecrets: 'Share Secrets',
    },
    secretsSharedWithMe: {
      title: 'Secrets Shared With Me',
      privateKeyFileInput: 'Select private key file',
      privateKeyFileInputHelper: 'It should have a .p8 extension',

      decryptSecrets: 'Decrypt secrets',
      hideSecrets: 'Hide secrets',
      table: {
        identifier: 'Identifier',
        time: 'Time',
        senderEmail: 'Sender Email',
      },
    },
    secretsSharedByMe: {
      title: 'Secrets Shared By Me',
      table: {
        recipientEmail: 'Recipient Email',
      },
    },
    table: {
      pagination: {
        rowsPerPage: 'Rows per page',
        countOf: 'of',
      },
      selectedLabel: {
        one: 'selected',
        moreThanOne: 'selected',
      },
      deleteLabel: 'Delete',
    },
    errorAlert: 'Error while sharing your secrets',
    successAlert: 'Secrets shared successfully',
    wrongKeyError: 'Wrong private key',
    emptyPublicKey: 'Please, go to "Generate key pair" section',
  },
  updatePublicKey: {
    info: 'Please update your public key if you want to provide your own key file, instead of relying on the app to generate one for you',
    publicKeyFileInput: 'Select a new public key file',
    submit: 'Upload new public key',
    titleAlert: 'File Upload',
    successAlert: 'Public Key stored successfully',
    errorAlert: 'Error while storing your public key',
  },
  utils: {
    yourFile: 'Your file',
  },
  tutorial: {
    buttonLabel: 'Tutorial',
    helpers: {
      back: 'Back',
      close: 'Close',
      next: 'Next',
      skip: 'Skip',
      last: 'Last',
      open: 'Open',
    },
    steps: {
      1: {
        content: 'Welcome to the tutorial of Secure Store',
      },
      2: {
        content: "First, let's generate a key pair",
      },
      3: {
        content: 'Name your new key pair',
      },
      4: {
        content: 'Generate your new key pair',
      },
      5: {
        content: 'Submit and download your new key pair',
      },
      6: {
        content: "Let's share a secret",
      },
      7: {
        content: 'Select the email of the recipient. If this is your first time, we suggest to select your email',
      },
      8: {
        content: 'Name your secret',
      },
      9: {
        content: 'Enter the secret value',
      },
      10: {
        content: "Add your secret to the secrets 'ready to share' list",
      },
      11: {
        content: "Now it's time to share your secret",
      },
      12: {
        content: 'In this section you will find the secrets that you shared with other users',
      },
      13: {
        content: 'In this section you will find the secrets shared with you and your own secrets',
      },
      14: {
        title: "Let's decrypt secrets!",
        content: 'Select the private key file that you just downloaded',
      },
      15: {
        content: 'Decrypt the secrets',
      },
      16: {
        content: "Let's select a secret",
      },
      17: {
        title: 'This icon is used to delete secrets',
        content: 'Click Next',
      },
      18: {
        content: 'Tutorial completed!',
      },
    },
  },
  colorMode: {
    title: 'Color Mode',
    dark: 'Dark',
    light: 'Light',
  },
};

export default LANG_EN;
