import { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';

import { HiddenAutoDownload } from 'components/controls';
import { ConfirmDialog } from 'components/dialog';
import { useGenerateUserKey } from './useGenerateUserKey';

export const GenerateUserKeyForm = ({ handleOpenSnackbar }) => {
  const {
    info,
    keyLengthHandler,
    keyLength,
    rsaPublicKey,
    rsaPrivateKey,
    isKeyLoading,
    isKeyUploading,
    generateKeyPair,
    uploadKeyPair,
    keyPairName,
    handleKeyPairName,
    rsaPrivateKeyDonwloadUrl,
    rsaPrivateKeyDonwloadUrlHandler,
    rsaPublicKeyDownloadUrl,
    rsaPublicKeyDownloadUrlHandler,
  } = useGenerateUserKey();

  const [openDialog, setOpenDialog] = useState(false);

  const toggleDialog = (_, reason) => {
    if (reason && reason === 'backdropClick') return;
    if (!isKeyUploading) setOpenDialog((prev) => !prev);
  };

  return (
    <>
      <ConfirmDialog
        name="generate-key-pair"
        dialogTitle={info.dialog.title}
        dialogContent={info.dialog.description}
        openDialog={openDialog}
        handleCloseDialog={toggleDialog}
        onConfirm={() => {
          uploadKeyPair()
            .then(() => {
              handleOpenSnackbar();
            })
            .finally(() => toggleDialog());
        }}
        cancelLabel={info.dialog.cancel}
        confirmLabel={info.dialog.ok}
        loading={isKeyUploading}
      />
      <Grid item container xs={12} direction="column" spacing={6}>
        <Grid item container spacing={4} xs={12}>
          <Grid item xs={5}>
            <TextField id="textfield-keypair-name" label={info.keyPairName} value={keyPairName} onChange={handleKeyPairName} />
          </Grid>
          <Grid item container spacing={2} xs={7}>
            <Grid item xs="auto">
              <Autocomplete
                disablePortal
                defaultValue="4096"
                id="key-length-select"
                options={['4096', '2048', '1024']}
                sx={{ minWidth: 200 }}
                renderInput={(params) => <TextField {...params} label={info.keyLength} contentEditable={false} />}
                onChange={keyLengthHandler}
              />
            </Grid>
            <Grid item xs="auto">
              <LoadingButton
                disabled={!keyLength || (rsaPublicKey !== '' && rsaPrivateKey !== '')}
                id="btn-generate-key-pair"
                className="btn-upload"
                loading={isKeyLoading}
                color="primary"
                variant="contained"
                component="span"
                onClick={generateKeyPair}
              >
                {info.title}
              </LoadingButton>
              <HiddenAutoDownload
                downloadName={keyPairName ? `${keyPairName}.p8` : 'privateKey.p8'}
                downloadUrl={rsaPrivateKeyDonwloadUrl}
                onCleanup={() => rsaPrivateKeyDonwloadUrlHandler(null)}
              />
              <HiddenAutoDownload
                downloadName={keyPairName ? `${keyPairName}.pub` : 'publicKey.pub'}
                downloadUrl={rsaPublicKeyDownloadUrl}
                onCleanup={() => rsaPublicKeyDownloadUrlHandler(null)}
              />
            </Grid>

            <Grid item xs="auto">
              <Button
                id="btn-upload-key-pair"
                className="btn-upload"
                disabled={rsaPublicKey === '' && rsaPrivateKey === ''}
                color="primary"
                variant="contained"
                component="span"
                onClick={toggleDialog}
              >
                {info.submitText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={10} justifyContent="space-evenly" alignItems="flex-start" spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="rsa-priv-key"
              label={info.privateKeyText}
              variant="outlined"
              multiline
              minRows={15}
              maxRows={15}
              fullWidth
              value={rsaPrivateKey}
              InputLabelProps={{ shrink: true }}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="rsa-pub-key"
              label={info.publicKeyText}
              variant="outlined"
              multiline
              minRows={15}
              maxRows={15}
              fullWidth
              value={rsaPublicKey}
              InputLabelProps={{ shrink: true }}
              inputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

GenerateUserKeyForm.defaultProps = {
  handleOpenSnackbar: () => {},
};

GenerateUserKeyForm.propTypes = {
  handleOpenSnackbar: PropTypes.func,
};

export default GenerateUserKeyForm;
