import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { SecureStorePageLayout } from 'components/layout';
import { SnackbarAlert, useSnackbarAlert } from 'components/alert';

import { useGenerateUserKey } from './useGenerateUserKey';
import { GenerateUserKeyForm } from './GenerateUserKey.form';

export const GenerateUserKey = () => {
  const { info } = useGenerateUserKey();

  const { handleCloseSnackbar, handleOpenSnackbar, openSnackbar } = useSnackbarAlert();

  return (
    <SecureStorePageLayout>
      <SnackbarAlert
        handleCloseSnackbar={handleCloseSnackbar}
        openSnackbar={openSnackbar}
        snackbarText={info.alertText}
        type="success"
        name="generateUserKey"
      />
      <Grid container item spacing={1}>
        <Grid item xs={7} mb={2}>
          <Alert severity="info" color="primary">
            <Typography>{info.infoText}</Typography>
          </Alert>
        </Grid>
        <Grid item xs={5} mb={2}>
          <Alert severity="info" color="primary">
            <Typography>{info.downloadKeyPair}</Typography>
          </Alert>
        </Grid>
      </Grid>
      <Grid item xs={12} alignContent="center">
        <Paper sx={{ p: 4 }}>
          <Grid item container xs={12} direction="row" spacing={5}>
            <Grid item xs={5} mb={3}>
              <Typography variant="subtitle1">{info.KeyPairDescription}</Typography>
              <Typography variant="caption" display="block">
                {info.advice}
              </Typography>
            </Grid>
            <Grid item xs={7} mb={3}>
              <Typography variant="subtitle1">{info.description}</Typography>
              <Typography variant="caption" display="block">
                {info.caption}
              </Typography>
            </Grid>
          </Grid>
          <GenerateUserKeyForm handleOpenSnackbar={handleOpenSnackbar} />
        </Paper>
      </Grid>
    </SecureStorePageLayout>
  );
};

export default GenerateUserKey;
