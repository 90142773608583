import { useContext, useState } from 'react';

import { LanguageContext } from 'context';
import { decryptSecrets } from 'services';
import { convertSecretsTimestampToLocalDate } from 'utils';
import { useSecrets } from './useSecrets';

export const useSecretsTable = (sharedWithMe = true) => {
  const [loading, setLoading] = useState(false);
  const [decryptedSecrets, setDecryptedSecrets] = useState([]);
  const [privateKey, setPrivateKey] = useState(null);

  const { fetchSecrets, secrets } = useSecrets();
  const { lang } = useContext(LanguageContext);
  const secretSize = decryptedSecrets.length;

  const decryptLocalyWithPrivateKeyFile = async () => {
    setLoading(true);
    const newSecrets = await decryptSecrets(
      privateKey,
      sharedWithMe ? secrets.secretsSharedWithMe : secrets.secretsSharedByMe,
      lang?.shareSecrets.wrongKeyError,
    );
    setDecryptedSecrets(newSecrets);
    setPrivateKey(null);
    setLoading(false);
  };

  const handlePrivateKey = (privateKeyFile) => {
    setPrivateKey(privateKeyFile);
  };

  const handleDecryptedSecrets = (newDecryptedSecrets) => {
    setDecryptedSecrets(newDecryptedSecrets);
  };

  /**
   * Table Info
   */

  const tableTitle = sharedWithMe ? lang?.shareSecrets.secretsSharedWithMe.title : lang?.shareSecrets.secretsSharedByMe.title;

  const tableTitles = [
    {
      text: lang?.shareSecrets.secretsSharedWithMe.table.identifier,
      mapping: 'secret_id',
    },
    {
      text: lang?.shareSecrets.secretValue,
      mapping: 'secret',
      obfuscated: !decryptedSecrets?.length,
      secretSize,
    },
    {
      text: lang?.shareSecrets.secretsSharedWithMe.table.time,
      mapping: 'created_at',
    },
    {
      text: sharedWithMe ? lang?.shareSecrets.secretsSharedWithMe.table.senderEmail : lang?.shareSecrets.secretsSharedByMe.table.recipientEmail,
      mapping: sharedWithMe ? 'sender_email' : 'recipient_email',
    },
  ];

  const tableHeaderContent = {
    selectFileLabel: lang?.shareSecrets.secretsSharedWithMe.privateKeyFileInput,
    selectFileCaption: lang?.shareSecrets.secretsSharedWithMe.privateKeyFileInputHelper,
    uploadButtonLabel: lang?.shareSecrets.secretsSharedWithMe.decryptSecrets,
  };

  const tableContent = decryptedSecrets?.length
    ? convertSecretsTimestampToLocalDate(decryptedSecrets)
    : convertSecretsTimestampToLocalDate(sharedWithMe ? secrets.secretsSharedWithMe : secrets.secretsSharedByMe);

  const decryptSecretsText = lang?.shareSecrets.secretsSharedWithMe.hideSecrets;

  return {
    fetchSecrets,
    loading,
    decryptedSecrets,
    decryptLocalyWithPrivateKeyFile,
    handlePrivateKey,
    handleDecryptedSecrets,
    privateKey,
    table: {
      title: tableTitle,
      titles: tableTitles,
      headerContent: tableHeaderContent,
      content: tableContent,
    },
    decryptSecretsText,
  };
};

export default useSecretsTable;
