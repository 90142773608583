import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import { GenerateUserKey, UpdateUserKey, Dashboard, Login } from 'pages';
import { ColorModeProvider, Provider } from 'context';

const App = () => {
  return (
    <Router>
      <ColorModeProvider>
        <Provider>
          <CssBaseline />
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/update-user-key" element={<UpdateUserKey />} />
            <Route path="/generate-user-key" element={<GenerateUserKey />} />
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Provider>
      </ColorModeProvider>
    </Router>
  );
};

export default App;
