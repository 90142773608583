import { useMemo, createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getCurrentUserSecrets } from 'services';
import { fieldSetterHandler } from 'utils';

export const SecretsContext = createContext({
  secrets: {
    secretsSharedWithMe: [],
    secretsSharedByMe: [],
  },
  secretsReadyToShare: [],
  handleSecretsReadyToShare: () => {},
  handleSecrets: () => {},
});

export const SecretsProvider = ({ children }) => {
  const [secrets, setSecrets] = useState({
    secretsSharedWithMe: [],
    secretsSharedByMe: [],
  });
  const [secretsReadyToShare, setSecretsReadyToShare] = useState([]);

  const secretsProviderValue = useMemo(
    () => ({
      secrets,
      secretsReadyToShare,
      handleSecretsReadyToShare: fieldSetterHandler(setSecretsReadyToShare),
      handleSecrets: fieldSetterHandler(setSecrets),
    }),
    [secrets, secretsReadyToShare],
  );

  useEffect(() => {
    getCurrentUserSecrets().then((userSecrets) => {
      setSecrets({
        secretsSharedWithMe: userSecrets.secrets_shared_with_me,
        secretsSharedByMe: userSecrets.secrets_shared_by_me,
      });
    });
  }, []);

  return <SecretsContext.Provider value={secretsProviderValue}>{children}</SecretsContext.Provider>;
};

SecretsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SecretsProvider;
