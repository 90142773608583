import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import { ListItemsWithIcon } from 'components/menuItems';
import { LanguageContext } from 'context';

const StyledDrawerContainer = (drawerWidth) =>
  styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

export const Drawer = ({ drawerWidth, open, variant, toggleDrawer, mainListItems, secondaryListItems, listSubheader }) => {
  const DrawerContainer = StyledDrawerContainer(drawerWidth);
  const { lang } = React.useContext(LanguageContext);

  return (
    <DrawerContainer open={open} variant={variant}>
      <Toolbar
        sx={{
          alignItems: 'center',
          gap: 1,
          px: [1],
        }}
      >
        <Box style={{ flex: 'auto' }}>
          <img src="logo.png" alt="Secure Store logo" width={33} style={{ margin: '8px 5px 0 4px' }} />
        </Box>

        <Typography color="primary" sx={{ flex: 'auto', fontWeight: 500, fontSize: 20 }}>
          {lang?.siteTitle}
        </Typography>

        <IconButton onClick={toggleDrawer}>
          <ArrowLeftIcon sx={{ fontSize: 37 }} />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {mainListItems?.length && <ListItemsWithIcon items={mainListItems} />}
        {secondaryListItems?.length && <Divider sx={{ my: 1 }} />}
        {secondaryListItems?.length && listSubheader && <ListItemsWithIcon items={secondaryListItems} subheader={listSubheader} />}
      </List>
    </DrawerContainer>
  );
};

Drawer.defaultProps = {
  secondaryListItems: null,
  listSubheader: null,
};

Drawer.propTypes = {
  variant: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  mainListItems: PropTypes.instanceOf(Array).isRequired,
  secondaryListItems: PropTypes.instanceOf(Array),
  listSubheader: PropTypes.string,
  toggleDrawer: PropTypes.func.isRequired,
};

export default Drawer;
