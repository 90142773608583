import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

export const ReadMore = ({ children }) => {
  const text = children;

  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = (e) => {
    e.stopPropagation();
    setIsReadMore(!isReadMore);
  };
  return text.length >= 155 ? (
    <Typography>
      {isReadMore ? text.slice(0, 150) : text}
      <Button onClick={toggleReadMore}>{isReadMore ? '...read more' : ' show less'}</Button>
    </Typography>
  ) : (
    <Typography>{text}</Typography>
  );
};

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReadMore;
