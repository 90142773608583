import { hexStringToArray } from './helpers';

const { crypto } = window;
const subtleCrypto = crypto.subtle;
const textEncoder = new TextDecoder();

export const getInitializationVector = () => crypto.getRandomValues(new Uint8Array(16));

export const encryptWithAesCbc = async (key, iv, message) => {
  const cryptoKey = await subtleCrypto.importKey('raw', hexStringToArray(key), 'AES-CBC', false, ['encrypt', 'decrypt']);
  const encryptedData = await subtleCrypto.encrypt({ name: 'AES-CBC', iv }, cryptoKey, Buffer.from(message));
  return Buffer.from(encryptedData).toString('hex');
};

export const decryptedWIthAes = async (key, iv, secret) => {
  const symetricKey = await subtleCrypto.importKey('raw', hexStringToArray(key), { name: 'AES-CBC' }, true, ['encrypt', 'decrypt']);
  const decryptedSecret = await subtleCrypto.decrypt({ name: 'AES-CBC', iv: hexStringToArray(iv) }, symetricKey, hexStringToArray(secret));
  return textEncoder.decode(decryptedSecret);
};
