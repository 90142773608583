import { useContext } from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { IconMapping } from 'components/mappings';
import { ColorModeContext, LanguageContext } from 'context';

export const DarkModeSelector = () => {
  const { colorMode, setColorMode } = useContext(ColorModeContext);
  const { lang } = useContext(LanguageContext);

  const Icon = colorMode === 'dark' ? IconMapping.DarkMode : IconMapping.LightMode;

  return (
    <Tooltip title={lang?.colorMode.title}>
      <IconButton
        onClick={() => {
          if (colorMode === 'dark') {
            setColorMode('light');
          } else {
            setColorMode('dark');
          }
        }}
      >
        <Icon fontSize="medium" color="inherit" sx={{ mr: 1 }} />
        <Typography component="h1" variant="h6" color="white" noWrap sx={{ flexGrow: 1 }}>
          {colorMode === 'dark' ? lang?.colorMode.dark : lang?.colorMode.light}
        </Typography>
      </IconButton>
    </Tooltip>
  );
};

export default DarkModeSelector;
