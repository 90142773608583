import PropTypes from 'prop-types';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { IconMapping } from 'components/mappings';

export const TableViewToolbar = ({ numSelected, selectedLabel, deleteLabel, onClickIcon }) => {
  const { DeleteIcon } = IconMapping;

  return (
    <Toolbar>
      {numSelected > 0 && (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} {selectedLabel}
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title={deleteLabel}>
          <IconButton id="btn-delete-selected-secrets" onClick={onClickIcon}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

TableViewToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selectedLabel: PropTypes.string.isRequired,
  deleteLabel: PropTypes.string.isRequired,
  onClickIcon: PropTypes.func.isRequired,
};

export default TableViewToolbar;
