import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useSecretInfo } from './useSecretInfo';
import { SecretInfoForm } from './SecretInfo.form';

export const SecretInfo = () => {
  const { users, addSecretToSend, secretInfoForm } = useSecretInfo();

  return (
    <Grid item xs={12} md={5} lg={4}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SecretInfoForm
          title={secretInfoForm.title}
          info={secretInfoForm.info}
          autocompleteOptions={users}
          buttonText={secretInfoForm.addSecretText}
          buttonClicked={addSecretToSend}
          clearAfterSubmit
        />
      </Paper>
    </Grid>
  );
};

export default SecretInfo;
