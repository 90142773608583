import axios from 'axios';
import { EnvManager } from 'config';

const baseUrl = EnvManager.BACKEND_URL;

export const uploadPublicKey = async (publicKeyFile, onUploadProgress, done) => {
  try {
    const data = new FormData();
    data.append('public_key', publicKeyFile);
    const response = await axios.post(`${baseUrl}/public-key`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
      onUploadProgress,
    });
    done();
    return response?.data;
  } catch (error) {
    done();
    return null;
  }
};

export default uploadPublicKey;
