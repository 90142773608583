/* eslint-disable no-console */
import { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { alpha } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Toolbar from '@mui/material/Toolbar';

import { LanguageContext, TourContext } from 'context';
import { Title } from 'components/text';
import { ConfirmDialog } from 'components/dialog';
import { TableViewToolbar } from './TableView.toolbar';
import { TableViewRow } from './TableView.row';

export const TableView = ({
  tableName,
  tableTitles,
  tableContent,
  title,
  showLink,
  linkHref,
  onLinkClick,
  linkText,
  headerContent,
  disableTitle,
  pagination,
  selectable,
  rowIdentifier,
  onDialogConfirm,
  dialogName,
  rowsPerPageOptions,
  maxRows,
}) => {
  const { lang } = useContext(LanguageContext);
  const {
    tour: { stepIndex, tourActive },
    handleTour,
  } = useContext(TourContext);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(maxRows);
  const [selected, setSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const toggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableContent.map((n) => (rowIdentifier !== 'id' ? n[rowIdentifier] : n.id));
      setSelected(newSelected);
      return;
    }
    setSelected([]);

    if (tourActive) handleTour({ run: true, stepIndex: stepIndex - 1 });
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelect = (_, identifier) => {
    const selectedIndex = selected.indexOf(identifier);
    const newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(identifier);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    if (newSelected.length === 0 && tourActive) {
      handleTour({ run: true, stepIndex: stepIndex - 1 });
    }

    setSelected(newSelected);
  };

  const titleRow = tableTitles?.map((tableTitle, index) => (
    <TableCell key={`${index}-${tableTitle.text}`} align={tableTitle.align || 'center'}>
      {tableTitle.text}
    </TableCell>
  ));

  const contentRows = tableContent.map((row, rowIndex) => {
    const isItemSelected = isSelected(rowIdentifier !== 'id' ? row[rowIdentifier] : row.id);
    return (
      <TableViewRow
        key={rowIdentifier !== 'id' ? row[rowIdentifier] : row.id}
        handleSelect={handleSelect}
        isItemSelected={isItemSelected}
        tableName={tableName}
        tableTitles={tableTitles}
        row={row}
        rowIdentifier={rowIdentifier}
        rowIndex={rowIndex}
        selectable={selectable}
      />
    );
  });

  return (
    <>
      {dialogName && onDialogConfirm && (
        <ConfirmDialog
          name={dialogName}
          dialogTitle={lang?.deleteSecrets.dialog.title}
          dialogContent={lang?.deleteSecrets.dialog.description}
          openDialog={openDialog}
          handleCloseDialog={toggleDialog}
          onConfirm={async () => {
            await onDialogConfirm(selected);
            setSelected([]);
            toggleDialog();
          }}
          cancelLabel={lang?.deleteSecrets.dialog.cancel}
          confirmLabel={lang?.deleteSecrets.dialog.ok}
        />
      )}
      <Toolbar disableGutters variant="dense">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            ...(selected.length > 0 && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
          }}
        >
          {!disableTitle && title && selected.length === 0 && (
            <Grid item>
              <Title>{title}</Title>
            </Grid>
          )}
          <Grid item>
            <TableViewToolbar
              selectedLabel={selected.length === 1 ? lang?.shareSecrets.table.selectedLabel.one : lang?.shareSecrets.table.selectedLabel.moreThanOne}
              deleteLabel={lang?.shareSecrets.table.deleteLabel}
              numSelected={selected.length}
              onClickIcon={toggleDialog}
            />
          </Grid>
          {headerContent && (
            <Grid item pr={2}>
              {headerContent}
            </Grid>
          )}
        </Grid>
      </Toolbar>
      <Table id={`table-${tableName}`} size="small" sx={{ mb: 2 }}>
        <TableHead>
          <TableRow role="checkbox">
            {selectable && (
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < tableContent.length}
                  checked={tableContent.length > 0 && selected.length === tableContent.length}
                  onChange={handleSelectAllClick}
                  color="primary"
                  id="checkbox-select-all-secrets-table"
                />
              </TableCell>
            )}
            {titleRow}
          </TableRow>
        </TableHead>
        <TableBody>{contentRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}</TableBody>
      </Table>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={tableContent.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={lang?.shareSecrets.table.pagination.rowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} ${lang?.shareSecrets.table.pagination.countOf} ${count !== -1 ? count : `more than ${to}`}`
          }
        />
      )}
      {showLink && onLinkClick && linkText && (
        <Link color="primary" href={linkHref || '#'} onClick={onLinkClick} sx={{ mt: 3 }}>
          {linkText}
        </Link>
      )}
    </>
  );
};

TableView.defaultProps = {
  showLink: false,
  linkHref: null,
  onLinkClick: null,
  linkText: null,
  headerContent: null,
  disableTitle: false,
  pagination: true,
  selectable: false,
  rowIdentifier: 'id',
  rowsPerPageOptions: [10, 25],
  maxRows: 10,
  title: null,
  dialogName: null,
  onDialogConfirm: null,
};

TableView.propTypes = {
  tableName: PropTypes.string.isRequired,
  tableTitles: PropTypes.instanceOf(Array).isRequired,
  tableContent: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.string,
  showLink: PropTypes.bool,
  linkHref: PropTypes.string,
  onLinkClick: PropTypes.func,
  linkText: PropTypes.string,
  headerContent: PropTypes.node,
  disableTitle: PropTypes.bool,
  pagination: PropTypes.bool,
  selectable: PropTypes.bool,
  rowIdentifier: PropTypes.string,
  onDialogConfirm: PropTypes.func,
  dialogName: PropTypes.string,
  rowsPerPageOptions: PropTypes.instanceOf(Array),
  maxRows: PropTypes.number,
};

export default TableView;
