/* eslint-disable react/no-unescaped-entities */
import { useContext } from 'react';
import Typography from '@mui/material/Typography';

import { LanguageContext } from 'context';
import { useTour } from 'hooks/useTour';

export const Tutorial = () => {
  const { lang } = useContext(LanguageContext);

  const STEPS = [
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['1'].content}</Typography>,
      placement: 'center',
      target: 'body',
      controlled: true,
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['2'].content}</Typography>,
      spotlightPadding: 1,
      target: '.generate-key-pair-link',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['3'].content}</Typography>,
      target: '#textfield-keypair-name',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['4'].content}</Typography>,
      target: '#btn-generate-key-pair',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['5'].content}</Typography>,
      target: '#btn-upload-key-pair',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['6'].content}</Typography>,
      spotlightPadding: 1,
      target: '.share-secrets-link',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['7'].content}</Typography>,
      target: '#combo-box-recipient',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['8'].content}</Typography>,
      target: '#secret-name',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['9'].content}</Typography>,
      target: '#secret-value',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['10'].content}</Typography>,
      target: '#add-secret-btn',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['11'].content}</Typography>,
      target: '#share-secret-btn',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['12'].content}</Typography>,
      target: '.tab-secrets-shared-by-me',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['13'].content}</Typography>,
      target: '.tab-secrets-shared-with-me',
    },
    {
      title: lang?.tutorial.steps['14'].title,
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['14'].content}</Typography>,
      target: '#btn-upload-icon',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['15'].content}</Typography>,
      target: '#btn-update-file',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['16'].content}</Typography>,
      target: '#table-secrets-shared-with-me tbody',
    },
    {
      title: lang?.tutorial.steps['17'].title,
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['17'].content}</Typography>,
      target: '#btn-delete-selected-secrets',
    },
    {
      content: <Typography variant="subtitle1">{lang?.tutorial.steps['18'].content}</Typography>,
      placement: 'center',
      target: 'body',
    },
  ];

  return useTour(STEPS);
};

export default Tutorial;
