import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ConfirmDialog } from 'components/dialog';

import { getUserFromSessionStorage, logoutUserSession } from 'services';
import { LanguageContext } from './LanguageContext';

export const SessionProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { lang } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    navigate('/login');
    setOpen(false);
  };

  const checkSession = () => {
    const sessionUser = getUserFromSessionStorage();
    const expiredDate = new Date(Number(sessionUser?.exp) * 1000);
    if (sessionUser && expiredDate < new Date()) {
      logoutUserSession();
      handleOpenDialog();
      window.event.stopImmediatePropagation();
    }
  };

  window.addEventListener('click', checkSession);

  return (
    <>
      <ConfirmDialog
        name="session"
        dialogTitle={lang?.session.title}
        dialogContent={lang?.session.text}
        openDialog={open}
        handleCloseDialog={handleCloseDialog}
      />
      {children}
    </>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SessionProvider;
