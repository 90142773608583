import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import { LanguageContext } from 'context';
import { IconMapping } from 'components/mappings';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#EEEEEE',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

export const FileUpload = ({
  customIcon,
  selectFile,
  selectFileLabel,
  selectFileCaption,
  selectedFile,
  onSecondaryAction,
  setAlert,
  uploadButtonLabel,
  uploadButtonSize,
  reportsProgress,
  isInline,
}) => {
  const FileIcon = IconMapping[customIcon || 'UploadFileIcon'];
  const UploadedIcon = IconMapping[customIcon || 'UploadFileDoneIcon'];

  const [progress, setProgress] = React.useState(0);

  const { lang } = React.useContext(LanguageContext);

  const trackProgress = (event) => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Grid container item spacing={2} alignItems="center">
        <Grid item xs={selectedFile ? 2 : 8}>
          {selectedFile ? (
            <UploadedIcon />
          ) : (
            <label htmlFor="btn-upload">
              <input id="btn-upload" name="btn-upload" style={{ display: 'none' }} type="file" onChange={selectFile} multiple={false} />
              <IconButton id="btn-upload-icon" component="span" edge="start" color="inherit" variant="outlined">
                <FileIcon sx={{ mr: 1, mb: selectFileCaption && 3 }} />
                <Typography variant="body2" color="inherit">
                  {selectFileLabel}
                  {selectFileCaption && (
                    <Typography display="block" variant="caption">
                      {selectFileCaption}
                    </Typography>
                  )}
                </Typography>
              </IconButton>
            </label>
          )}
        </Grid>

        {selectedFile && (
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <Typography align="left" variant={isInline ? 'caption' : 'h6'} color="textPrimary">
                {selectedFile && `${lang?.utils.yourFile}: ${selectedFile.name}`}
              </Typography>
            </Box>
          </Grid>
        )}

        <Grid item xs={selectedFile ? 6 : 4}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              id="btn-update-file"
              className="btn-upload"
              color="primary"
              variant="contained"
              size={uploadButtonSize ?? 'medium'}
              disabled={!selectedFile}
              onClick={() =>
                onSecondaryAction(trackProgress).then((result) => {
                  setProgress(0);
                  if (setAlert) {
                    setAlert(result);
                  }
                }) || (() => {})
              }
            >
              {uploadButtonLabel}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container item spacing={2} alignItems="center">
        <Grid item xs={9}>
          {selectedFile && reportsProgress && progress ? (
            <Box display="flex" alignItems="center">
              <Box>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

FileUpload.defaultProps = {
  customIcon: null,
  setAlert: () => {},
  selectedFile: null,
  reportsProgress: false,
  selectFileCaption: null,
  uploadButtonSize: 'medium',
};

FileUpload.propTypes = {
  customIcon: PropTypes.element,
  setAlert: PropTypes.func,
  selectFile: PropTypes.func.isRequired,
  selectFileLabel: PropTypes.string.isRequired,
  selectFileCaption: PropTypes.string,
  selectedFile: PropTypes.objectOf(File),
  onSecondaryAction: PropTypes.func.isRequired,
  uploadButtonLabel: PropTypes.string.isRequired,
  uploadButtonSize: PropTypes.oneOf(['small', 'medium', 'large']),
  reportsProgress: PropTypes.bool,
  isInline: PropTypes.bool.isRequired,
};

export default FileUpload;
