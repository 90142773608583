import { useContext } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { FileUpload, TableView } from 'components/controls';
import { useSecretsTable } from 'hooks';
import { deleteSecrets } from 'services';
import { SnackbarAlert, useSnackbarAlert } from 'components/alert';
import { LanguageContext } from 'context';

export const SecretsTable = ({ sharedWithMe }) => {
  const {
    fetchSecrets,
    loading,
    decryptedSecrets,
    handleDecryptedSecrets,
    handlePrivateKey,
    privateKey,
    table,
    decryptLocalyWithPrivateKeyFile,
    decryptSecretsText,
  } = useSecretsTable(sharedWithMe);

  const { handleCloseSnackbar, handleOpenSnackbar, openSnackbar } = useSnackbarAlert();

  const { lang } = useContext(LanguageContext);

  const handleDeleteSecrets = async (secretIds) => {
    await deleteSecrets(secretIds).then(() => {
      fetchSecrets().then(() => {
        handleDecryptedSecrets([]);
      });
      handleOpenSnackbar();
    });
  };

  return (
    <>
      <SnackbarAlert
        handleCloseSnackbar={handleCloseSnackbar}
        openSnackbar={openSnackbar}
        snackbarText={lang?.deleteSecrets.snackbarAlert.description}
        type="success"
        name="delete-secrets"
      />
      <TableView
        tableName={sharedWithMe ? 'secrets-shared-with-me' : 'secrets-shared-by-me'}
        dialogName="delete-secrets"
        rowIdentifier="secret_id"
        selectable
        tableTitles={table.titles}
        tableContent={table.content}
        disableTitle
        onDialogConfirm={handleDeleteSecrets}
        headerContent={
          !decryptedSecrets?.length ? (
            <FileUpload
              selectFile={(e) => {
                handlePrivateKey(e.target?.files[0]);
              }}
              selectedFile={privateKey}
              selectFileLabel={table?.headerContent.selectFileLabel}
              selectFileCaption={table?.headerContent.selectFileCaption}
              uploadButtonLabel={table?.headerContent.uploadButtonLabel}
              onSecondaryAction={decryptLocalyWithPrivateKeyFile}
              isInline
              isUploadButtonHidden={decryptedSecrets?.length}
              uploadButtonSize="small"
            />
          ) : (
            <Button disabled={loading} color="primary" variant="contained" size="small" onClick={() => handleDecryptedSecrets([])}>
              {decryptSecretsText}
            </Button>
          )
        }
      />
    </>
  );
};

SecretsTable.defaultProps = {
  sharedWithMe: true,
};

SecretsTable.propTypes = {
  sharedWithMe: PropTypes.bool,
};

export default SecretsTable;
