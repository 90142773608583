import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const ConfirmDialog = ({ dialogTitle, dialogContent, name, openDialog, handleCloseDialog, onConfirm, cancelLabel, confirmLabel, loading }) => {
  return (
    <Dialog
      id={`alert-dialog-${name}`}
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby={`alert-dialog-title-${name}`}
      aria-describedby={`alert-dialog-description-${name}`}
    >
      <DialogTitle id={`alert-dialog-title-${name}`} color="primary">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`alert-dialog-description-${name}`}>{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelLabel && (
          <Button id={`alert-dialog-cancel-${name}`} onClick={handleCloseDialog} color="primary" disabled={loading}>
            {cancelLabel}
          </Button>
        )}
        <Button id={`alert-dialog-confirm-${name}`} onClick={onConfirm ?? handleCloseDialog} color="inherit" disabled={loading}>
          {confirmLabel ?? 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  confirmLabel: null,
  cancelLabel: null,
  onConfirm: null,
  loading: null,
};

ConfirmDialog.propTypes = {
  name: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogContent: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  loading: PropTypes.bool,
};

export default ConfirmDialog;
