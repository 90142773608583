import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const createAlert = (title, message, highlight, severity = 'info') => ({
  title,
  message,
  highlight,
  severity,
});

export const AlertSection = ({ alertsFromProps, parentName, onAlertTimeout, clearTime }) => {
  const [alerts, setAlerts] = React.useState(alertsFromProps || []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setAlerts([]);
      onAlertTimeout();
    }, clearTime || 5000);

    return () => clearTimeout(timer);
  }, [clearTime, onAlertTimeout]);

  const alertsToShow = alerts.map((alert, index) => (
    <Grid item key={`${parentName}-alert-${index}`} xs={12}>
      <Alert id={`${parentName}-alert`} severity={alert.severity}>
        <AlertTitle>{alert.title}</AlertTitle>
        {alert.message} <strong>{alert.highlight}</strong>
      </Alert>
    </Grid>
  ));

  return (
    alerts.length && (
      <Grid
        item
        container
        spacing={2}
        xs={12}
        sx={{
          maxHeight: '12vh',
          overflowY: 'auto',
        }}
      >
        {alertsToShow}
      </Grid>
    )
  );
};

AlertSection.defaultProps = {
  clearTime: () => {},
};

AlertSection.propTypes = {
  alertsFromProps: PropTypes.instanceOf(Array).isRequired,
  parentName: PropTypes.string.isRequired,
  onAlertTimeout: PropTypes.func.isRequired,
  clearTime: PropTypes.func,
};

export default AlertSection;
