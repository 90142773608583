import { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const ColorModeContext = createContext({
  colorMode: '',
  setColorMode: () => {},
});

export const ColorModeProvider = ({ children }) => {
  const colorModeFromLocalStorage = localStorage.getItem('colorMode');
  const [colorMode, setColorMode] = useState(colorModeFromLocalStorage === 'dark' ? 'dark' : 'light');

  useEffect(() => {
    setColorMode(colorModeFromLocalStorage === 'dark' ? 'dark' : 'light');
  }, [colorModeFromLocalStorage]);

  const onColorModeChange = (colorModeSelected) => {
    setColorMode(colorModeSelected);
    localStorage.setItem('colorMode', colorModeSelected);
  };

  const providerValue = useMemo(() => ({ colorMode, setColorMode: onColorModeChange }), [colorMode]);

  return <ColorModeContext.Provider value={providerValue}>{children}</ColorModeContext.Provider>;
};

ColorModeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ColorModeProvider;
