import { useContext } from 'react';

import { SecretsContext } from 'context';

import { getCurrentUserSecrets, performKeyAgreement, shareSecrets } from 'services';

export const useSecrets = () => {
  const { secrets, secretsReadyToShare, handleSecrets, handleSecretsReadyToShare } = useContext(SecretsContext);

  const addSecretToSend = (e, secretName, secretValue, recipient) => {
    handleSecretsReadyToShare([...secretsReadyToShare, { recipient, name: secretName, value: secretValue }]);
  };

  const fetchSecrets = async () => {
    return getCurrentUserSecrets().then((userSecrets) => {
      handleSecrets({
        secretsSharedWithMe: userSecrets.secrets_shared_with_me,
        secretsSharedByMe: userSecrets.secrets_shared_by_me,
      });
    });
  };

  const sendSecrets = async () => {
    const secureSecretSharing = async () => {
      const sharedSecret = await performKeyAgreement();
      return shareSecrets(secretsReadyToShare, sharedSecret);
    };

    await secureSecretSharing()
      .then(() => {
        fetchSecrets();
      })
      .finally(() => handleSecretsReadyToShare([]));
  };

  return {
    fetchSecrets,
    secrets,
    secretsReadyToShare,
    addSecretToSend,
    sendSecrets,
  };
};

export default useSecrets;
