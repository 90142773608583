/* eslint-disable consistent-return */
import { decryptedWIthAes } from './aesFunctions';
import { decryptWithPrivateKey } from './rsaFunctions';

export const decryptWithRsaAes = async (privateKeyArrayBuffer, secretData, errorMessage) => {
  try {
    const decryptedKey = decryptWithPrivateKey(privateKeyArrayBuffer, secretData.encrypted_symetric_key);
    const [symetricKey, iv] = decryptedKey.split(',');
    const secret = await decryptedWIthAes(symetricKey, iv, secretData.secret);
    return {
      ...secretData,
      secret,
    };
  } catch {
    return {
      ...secretData,
      secret: errorMessage,
    };
  }
};

export const legacyRSADecryption = async (privateKeyArrayBuffer, secretData, errorMessage) => {
  try {
    const secret = decryptWithPrivateKey(privateKeyArrayBuffer, secretData.secret);
    return {
      ...secretData,
      secret,
    };
  } catch {
    return {
      ...secretData,
      secret: errorMessage,
    };
  }
};

const decryptStrategies = {
  newMethod: decryptWithRsaAes,
  legacyMethod: legacyRSADecryption,
};

const getStrategy = async (privateKeyArrayBuffer, secret, errorMessage) => {
  const status = secret.encrypted_symetric_key ? 'newMethod' : 'legacyMethod';
  const strategy = await decryptStrategies[status](privateKeyArrayBuffer, secret, errorMessage);
  return strategy;
};

export const decryptSecrets = async (privateKey, secrets, errorMessage) => {
  if (!privateKey || !secrets) return;
  const privateKeyArrayBuffer = await privateKey.arrayBuffer();
  const decryptedSecrets = await Promise.all(
    secrets.map(async (secretData) => {
      return getStrategy(privateKeyArrayBuffer, secretData, errorMessage);
    }),
  );
  return decryptedSecrets;
};
