import Grid from '@mui/material/Grid';

import { SecureStorePageLayout } from 'components/layout';

import { SecretInfo } from '../SecretInfo';
import { SecretsReadyToShare } from '../SecretsReadyToShare';
import { SecretsShared } from '../SecretsShared';

export const Dashboard = () => {
  return (
    <SecureStorePageLayout>
      <Grid container spacing={3}>
        <SecretInfo />
        <SecretsReadyToShare />
        <SecretsShared />
      </Grid>
    </SecureStorePageLayout>
  );
};

export default Dashboard;
