import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarAlert = ({ openSnackbar, handleCloseSnackbar, snackbarText, type, name }) => {
  const colors = {
    success: 'primary',
    info: 'info',
    error: 'error',
  };

  return (
    <Snackbar open={openSnackbar} autoHideDuration={3500} onClose={handleCloseSnackbar}>
      <Alert
        onClose={handleCloseSnackbar}
        severity={type}
        color={colors[type]}
        sx={{ width: '100%' }}
        id={`${name}-alert-${type === 'success' ? 'success' : 'error'}`}
      >
        {snackbarText}
      </Alert>
    </Snackbar>
  );
};

SnackbarAlert.defaultProps = {
  type: 'success',
};

SnackbarAlert.propTypes = {
  openSnackbar: PropTypes.bool.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  snackbarText: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'info']),
  name: PropTypes.string.isRequired,
};

export default SnackbarAlert;
