import { useContext } from 'react';
import PropTypes from 'prop-types';

import blue from '@mui/material/colors/blue';
import orange from '@mui/material/colors/orange';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { EnvManager } from 'config';
import { LanguageProvider } from './LanguageContext';
import { SessionProvider } from './SessionProvider';
import { SecretsProvider } from './SecretsContext';
import { ColorModeContext } from './ColorModeContext';
import { TourProvider } from './TourContext';

export const Provider = ({ children }) => {
  const { colorMode } = useContext(ColorModeContext);

  const mdTheme = createTheme({
    palette: {
      mode: colorMode || 'light',
      primary: {
        main: EnvManager.ENVIRONMENT === 'production' ? blue[700] : orange[700],
        contrastText: '#fff',
      },
    },
  });

  return (
    <ThemeProvider theme={mdTheme}>
      <LanguageProvider>
        <SessionProvider>
          <SecretsProvider>
            <TourProvider>{children}</TourProvider>
          </SecretsProvider>
        </SessionProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
