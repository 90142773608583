import { useState } from 'react';

export const useSnackbarAlert = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  return {
    openSnackbar,
    handleCloseSnackbar,
    handleOpenSnackbar,
  };
};

export default useSnackbarAlert;
