import Key from '@mui/icons-material/Key';
import Logout from '@mui/icons-material/Logout';
import GoogleIcon from '@mui/icons-material/Google';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import BarChartIcon from '@mui/icons-material/BarChart';
import LockResetIcon from '@mui/icons-material/LockReset';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Upload from '@mui/icons-material/Upload';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LanguageIcon from '@mui/icons-material/Language';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import DarkMode from '@mui/icons-material/DarkMode';
import LightMode from '@mui/icons-material/LightMode';
import DeleteIcon from '@mui/icons-material/Delete';

export const IconMapping = Object.freeze({
  Dashboard: DashboardIcon,
  ShoppingCart: ShoppingCartIcon,
  People: PeopleIcon,
  BarChart: BarChartIcon,
  Layers: LayersIcon,
  Assignment: AssignmentIcon,
  Logout,
  Key,
  UploadFileIcon: Upload,
  UploadFileDoneIcon: DownloadDoneIcon,
  Google: GoogleIcon,
  LockReset: LockResetIcon,
  Visibility: VisibilityIcon,
  VisibilityOff: VisibilityOffIcon,
  LanguageIcon,
  LocalLibraryIcon,
  DarkMode,
  LightMode,
  DeleteIcon,
});

export default IconMapping;
