import axios from 'axios';
import { EnvManager } from 'config';

const baseUrl = `${EnvManager.BACKEND_URL}/users`;

export const getUsers = async () => {
  try {
    const response = await axios.get(baseUrl, {
      withCredentials: true,
    });
    return response?.data;
  } catch (error) {
    return null;
  }
};

const getActiveUserSession = async () => {
  try {
    const response = await axios.get(`${baseUrl}/current`, {
      withCredentials: true,
    });
    return response?.data;
  } catch (error) {
    return null;
  }
};

export const getActiveUserPublicKey = async () => {
  try {
    const response = await axios.get(`${baseUrl}/current/public-key`, {
      withCredentials: true,
    });
    const currentUserInfo = response?.data;
    return {
      name: currentUserInfo?.name,
      publicKey: currentUserInfo?.public_key,
    };
  } catch (error) {
    return null;
  }
};

export const getUserFromSessionStorage = () => {
  if (!sessionStorage.getItem('isUserActive')) return null;
  return {
    name: sessionStorage.getItem('userName'),
    exp: sessionStorage.getItem('userExp'),
    email: sessionStorage.getItem('userEmail'),
    picture: sessionStorage.getItem('userPicture'),
    publicKey: sessionStorage.getItem('userPublicKey'),
  };
};

const setUserInSessionStorage = (user, isActive) => {
  sessionStorage.setItem('isUserActive', isActive);
  sessionStorage.setItem('userName', user.name);
  sessionStorage.setItem('userExp', user.exp);
  sessionStorage.setItem('userEmail', user.email);
  sessionStorage.setItem('userPicture', user.picture);
  sessionStorage.setItem('userPublicKey', user.public_key);
};

export const didUserActivateSession = async () => {
  const sessionUser = getUserFromSessionStorage();

  if (sessionUser && sessionUser.exp < new Date().getTime()) return sessionUser;
  const user = await getActiveUserSession();
  if (user) {
    setUserInSessionStorage(user, true);
    return user;
  }
  return null;
};
