import { useContext, useEffect, useState } from 'react';

import { useSecrets } from 'hooks';
import { getUsers } from 'services';
import { LanguageContext } from 'context';

export const useSecretInfo = () => {
  const [users, setUsers] = useState([]);

  const { addSecretToSend } = useSecrets();
  const { lang } = useContext(LanguageContext);

  const secretInfoForm = {
    title: lang?.shareSecrets.secretInfo.title,
    info: lang?.shareSecrets.secretInfo.recipientInfo,
    users,
    addSecretText: lang?.shareSecrets.secretInfo.addSecret,
  };

  useEffect(() => {
    getUsers().then((registeredUsers) => {
      setUsers(registeredUsers?.map((user) => user.user_email));
    });
  }, []);

  return { users, secretInfoForm, addSecretToSend };
};

export default useSecretInfo;
