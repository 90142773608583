import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { SnackbarAlert, useSnackbarAlert } from 'components/alert';

import { useCurrentUser } from 'hooks';
import { SecretsBreakdown } from './SecretsBreakdown';
import { useSecretsReadyToShare } from './useSecretsReadyToShare';

export const SecretsReadyToShare = () => {
  const { alertText, secretsReadyToShare, sendSecrets } = useSecretsReadyToShare();
  const { currentUser } = useCurrentUser();

  const { handleCloseSnackbar, handleOpenSnackbar, openSnackbar } = useSnackbarAlert();

  return (
    <Grid item xs={12} md={7} lg={8}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SnackbarAlert handleCloseSnackbar={handleCloseSnackbar} openSnackbar={openSnackbar} snackbarText={alertText} name="shareSecrets" type="success" />
        <SecretsBreakdown
          currentUserPublicKey={currentUser?.publicKey}
          secretsToShare={secretsReadyToShare}
          onButtonClick={async () => {
            await sendSecrets().then(() => handleOpenSnackbar());
          }}
        />
      </Paper>
    </Grid>
  );
};

export default SecretsReadyToShare;
