import React from 'react';
import PropTypes from 'prop-types';

import { AppBar, Drawer } from 'components/controls';

export const AppBarDrawerCombo = ({ drawerWidth, tooltipMenuItems, avatarName, avatarImgSrc, drawerMenuItems }) => {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <AppBar
        position="absolute"
        open={open}
        toggleDrawer={toggleDrawer}
        drawerWidth={drawerWidth}
        tooltipMenuItems={tooltipMenuItems}
        avatarName={avatarName}
        avatarImgSrc={avatarImgSrc}
      />
      <Drawer variant="permanent" open={open} mainListItems={drawerMenuItems} toggleDrawer={toggleDrawer} drawerWidth={drawerWidth} />
    </>
  );
};

AppBarDrawerCombo.defaultProps = {
  avatarName: null,
};

AppBarDrawerCombo.propTypes = {
  drawerWidth: PropTypes.number.isRequired,
  drawerMenuItems: PropTypes.instanceOf(Array).isRequired,
  tooltipMenuItems: PropTypes.instanceOf(Array).isRequired,
  avatarName: PropTypes.string,
  avatarImgSrc: PropTypes.string.isRequired,
};

export default AppBarDrawerCombo;
