import React from 'react';
import PropTypes from 'prop-types';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import { IconMapping } from 'components/mappings';

export const ListItemsWithIcon = ({ items, subheader }) => {
  const content = items
    ? items.map((item, index) => {
        const Icon = IconMapping[item.icon];
        return (
          <ListItemButton key={`${index}-${item.text}-iconItem`} onClick={item.onClick || (() => {})} className={item?.className || null}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        );
      })
    : null;

  return (
    <>
      {subheader && (
        <ListSubheader component="div" inset>
          {subheader}
        </ListSubheader>
      )}
      {content}
    </>
  );
};

ListItemsWithIcon.defaultProps = {
  subheader: null,
};

ListItemsWithIcon.propTypes = {
  subheader: PropTypes.string,
  items: PropTypes.instanceOf(Array).isRequired,
};

export default ListItemsWithIcon;
