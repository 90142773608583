import React from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import { IconMapping } from 'components/mappings';

export const TooltipMenu = ({ tooltipTitle, menuId, items, children }) => {
  const [tooltipMenuAnchor, setTtooltipMenuAnchor] = React.useState(null);
  const open = Boolean(tooltipMenuAnchor);
  const onTooltipMenuClose = () => setTtooltipMenuAnchor(null);
  const onIconClick = (event) => setTtooltipMenuAnchor(event.currentTarget);
  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton
          onClick={onIconClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {children}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={tooltipMenuAnchor}
        id={menuId}
        open={open}
        onClose={onTooltipMenuClose}
        onClick={onTooltipMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {items.map((item, index) => {
          const Icon = item.icon ? IconMapping[item.icon] : null;
          return (
            <MenuItem id={item?.id} key={`tooltipMenuItem-${index}`} onClick={item.onClick || (() => {})}>
              {Icon && (
                <ListItemIcon>
                  <Icon fontSize="small" />
                </ListItemIcon>
              )}
              {item.title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

TooltipMenu.propTypes = {
  tooltipTitle: PropTypes.string.isRequired,
  menuId: PropTypes.string.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  children: PropTypes.node.isRequired,
};

export default TooltipMenu;
