import { useState, useContext } from 'react';

import { LanguageContext } from 'context';
import { generateRSAKeyPair, uploadPublicKey } from 'services';
import { fieldSetterHandler } from 'utils';

export const useGenerateUserKey = () => {
  const [keyLength, setKeyLength] = useState(4096);
  const [keyPairName, updateKeyPairName] = useState('');
  const [rsaPublicKey, setRsaPublicKey] = useState('');
  const [rsaPublicKeyDownloadUrl, setRsaPublicKeyDonwloadUrl] = useState(null);
  const [rsaPrivateKey, setRsaPrivateKey] = useState('');
  const [rsaPrivateKeyDonwloadUrl, setRsaPrivateKeyDonwloadUrl] = useState(null);
  const [isKeyLoading, setIsKeyLoading] = useState(false);
  const [isKeyUploading, setIsKeyUploading] = useState(false);

  const { lang } = useContext(LanguageContext);

  const publicKeyName = keyPairName ? `${keyPairName}.pub` : 'publicKey.pub';

  const handleKeyPairName = (event) => {
    const { value } = event.target;
    updateKeyPairName(value);
  };

  const generateKeyPair = () => {
    setIsKeyLoading(true);
    generateRSAKeyPair(keyLength).then((rsaKeyPair) => {
      setRsaPrivateKey(rsaKeyPair.rsaPrivateKey);
      setRsaPublicKey(rsaKeyPair.rsaPublicKey);
      setIsKeyLoading(false);
    });
  };

  const uploadKeyPair = async () => {
    setIsKeyUploading(true);
    const pubKeyBlob = new Blob([rsaPublicKey]);
    const privKeyBlob = new Blob([rsaPrivateKey]);
    await uploadPublicKey(
      new File([pubKeyBlob], publicKeyName),
      () => {},
      () => {},
    )
      .then(() => {
        setRsaPublicKeyDonwloadUrl(URL.createObjectURL(pubKeyBlob));
        setRsaPrivateKeyDonwloadUrl(URL.createObjectURL(privKeyBlob));
        setRsaPrivateKey('');
        setRsaPublicKey('');
      })
      .finally(() => {
        setIsKeyUploading(false);
      });
  };

  const keyLengthHandler = (_e, value) => {
    setKeyLength(parseInt(value, 10));
  };

  /**
   * i18n labels
   */

  const generateUserKeyInfo = {
    infoText: lang?.generateKeyPair.info,
    alertText: lang?.generateKeyPair.successAlert,
    downloadKeyPair: lang?.generateKeyPair.infoDownloadKeyPair,
    title: lang?.generateKeyPair.title,
    description: lang?.generateKeyPair.description,
    KeyPairDescription: lang?.generateKeyPair.KeyPairDescription,
    keyPairName: lang?.generateKeyPair.keyPairName,
    advice: lang?.generateKeyPair.adviceNameKeyPair,
    caption: lang?.generateKeyPair.advice,
    keyLength: lang?.generateKeyPair.keyLength,
    submitText: lang?.generateKeyPair.submit,
    privateKeyText: lang?.generateKeyPair.privateKey,
    publicKeyText: lang?.generateKeyPair.publicKey,
    dialog: {
      title: lang?.generateKeyPair.dialog.title,
      description: lang?.generateKeyPair.dialog.description,
      ok: lang?.generateKeyPair.dialog.ok,
      cancel: lang?.generateKeyPair.dialog.cancel,
    },
  };

  return {
    info: generateUserKeyInfo,
    keyLengthHandler,
    keyLength,
    rsaPublicKey,
    rsaPublicKeyDownloadUrl,
    rsaPublicKeyDownloadUrlHandler: fieldSetterHandler(setRsaPublicKeyDonwloadUrl),
    rsaPrivateKey,
    rsaPrivateKeyDonwloadUrl,
    rsaPrivateKeyDonwloadUrlHandler: fieldSetterHandler(setRsaPrivateKeyDonwloadUrl),
    isKeyLoading,
    isKeyUploading,
    generateKeyPair,
    uploadKeyPair,
    keyPairName,
    handleKeyPairName,
  };
};

export default useGenerateUserKey;
