/* eslint-disable no-console */
import { useCallback, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import JoyRide, { STATUS } from 'react-joyride';

import blue from '@mui/material/colors/blue';
import orange from '@mui/material/colors/orange';
import grey from '@mui/material/colors/grey';

import { EnvManager } from 'config';
import { ColorModeContext, LanguageContext } from 'context';
import { TourContext } from 'context/TourContext';

export const useTour = (steps) => {
  const { colorMode } = useContext(ColorModeContext);
  const { lang } = useContext(LanguageContext);

  const {
    tour: { run, stepIndex },
    handleTour,
  } = useContext(TourContext);

  const navigate = useNavigate();

  const getNextOrPrevStep = (action, index) => {
    let idx = index;
    if (action === 'next') idx += 1;
    if (action === 'prev') idx -= 1;
    return idx;
  };

  const handleJoyrideCallback = useCallback(
    (data) => {
      const { action, status, index, type } = data;

      const idx = getNextOrPrevStep(action, index);

      const routePath = {
        2: '/generate-user-key',
        4: '/generate-user-key',
        6: '/dashboard',
      };

      if (type === 'step:after') {
        handleTour({ run: true, stepIndex: idx, tourActive: true });
        if (routePath[idx]) navigate(routePath[idx]);
      }

      if (type === 'error:target_not_found') {
        handleTour({ run: true, stepIndex: index - 1, tourActive: true });
      }

      if (action === 'close' && index === 0) {
        handleTour({ run: false, stepIndex: 0, tourActive: false });
      }

      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
      if (finishedStatuses.includes(status)) {
        handleTour({ run: false, tourActive: false, stepIndex: 0 });
      }
    },
    [handleTour, navigate],
  );

  const joyrideStyles = useMemo(
    () => ({
      options: {
        zIndex: 10000,
        primaryColor: EnvManager.ENVIRONMENT === 'production' ? blue[700] : orange[700],
        width: 350,
        backgroundColor: colorMode === 'light' ? grey[100] : grey[900],
        textColor: colorMode === 'light' ? '#000' : '#fff',
      },
    }),
    [colorMode],
  );

  const tour = useMemo(
    () => (
      <JoyRide
        callback={handleJoyrideCallback}
        continuous
        run={run}
        disableScrolling
        showProgress
        showSkipButton
        steps={steps}
        styles={joyrideStyles}
        stepIndex={stepIndex}
        locale={{
          back: lang?.tutorial.helpers.back,
          close: lang?.tutorial.helpers.close,
          last: lang?.tutorial.helpers.last,
          next: lang?.tutorial.helpers.next,
          open: lang?.tutorial.helpers.open,
          skip: lang?.tutorial.helpers.skip,
        }}
      />
    ),
    [handleJoyrideCallback, run, steps, joyrideStyles, stepIndex, lang],
  );

  return tour;
};

export default useTour;
