import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const HiddenAutoDownload = (props) => {
  const { downloadUrl, downloadName, onCleanup } = props;
  const elementRef = useRef();

  useEffect(() => {
    if (!downloadUrl) return;
    elementRef.current.click();
    URL.revokeObjectURL(downloadUrl);
    onCleanup();
  }, [downloadUrl, onCleanup]);

  return (
    <a style={{ display: 'none' }} download={downloadName} href={downloadUrl} ref={elementRef}>
      download
    </a>
  );
};

HiddenAutoDownload.defaultProps = {
  downloadUrl: null,
};

HiddenAutoDownload.propTypes = {
  downloadUrl: PropTypes.string,
  downloadName: PropTypes.string.isRequired,
  onCleanup: PropTypes.func.isRequired,
};

export default HiddenAutoDownload;
