export const convertSecretsTimestampToLocalDate = (secretsToFormat) => {
  return secretsToFormat
    ?.sort((secretA, secretB) => new Date(secretB.created_at) - new Date(secretA.created_at))
    ?.map((secret) => {
      const localSecretDate = new Date(secret.created_at * 1000);
      const secretDateTime = `${localSecretDate.toLocaleDateString('en-GB')} ${localSecretDate.toLocaleTimeString('en-US')}`;

      return { ...secret, created_at: secretDateTime };
    });
};

export default convertSecretsTimestampToLocalDate;
