import { useEffect, useState } from 'react';

import { getActiveUserPublicKey } from 'services';

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    getActiveUserPublicKey().then((userWithKey) => {
      if (!userWithKey) {
        return;
      }
      setCurrentUser(userWithKey);
    });
  }, []);

  return {
    currentUser,
  };
};

export default useCurrentUser;
