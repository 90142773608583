import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const initialTourState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const TourContext = createContext({
  tour: initialTourState,
  handleTour: () => null,
});

export const TourProvider = ({ children }) => {
  const [tour, setTourState] = useState(initialTourState);

  const providerValue = useMemo(() => ({ tour, handleTour: setTourState }), [tour]);

  return <TourContext.Provider value={providerValue}>{children}</TourContext.Provider>;
};

TourProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TourProvider;
