import { useContext } from 'react';

import { useSecrets } from 'hooks';
import { LanguageContext } from 'context';

export const useSecretsReadyToShare = () => {
  const { lang } = useContext(LanguageContext);
  const { secretsReadyToShare, sendSecrets } = useSecrets();

  const alertText = lang?.shareSecrets.successAlert;

  return {
    alertText,
    secretsReadyToShare,
    sendSecrets,
  };
};

export default useSecretsReadyToShare;
