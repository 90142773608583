import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export const Copyright = (props) => {
  const { goto, websitetitle } = props;

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {websitetitle && goto && (
        <Link color="inherit" href={goto}>
          {websitetitle}
        </Link>
      )}{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
};

Copyright.defaultProps = {
  websitetitle: null,
  goto: null,
};

Copyright.propTypes = {
  websitetitle: PropTypes.string,
  goto: PropTypes.string,
};

export default Copyright;
