import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { ReadMore } from 'components/sections/ReadMore';

export const TableViewRow = ({ handleSelect, isItemSelected, tableName, tableTitles, row, rowIdentifier, rowIndex, selectable }) => {
  return (
    <TableRow
      className={`${tableName}-table-row`}
      onClick={(event) => selectable && handleSelect(event, rowIdentifier !== 'id' ? row[rowIdentifier] : row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      key={`row-${rowIndex}`}
      selected={isItemSelected}
    >
      {selectable && (
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} color="primary" />
        </TableCell>
      )}
      {tableTitles.map((titleContentRow, cellIndex) => (
        <TableCell
          key={`${rowIndex}-${cellIndex}-cell-${titleContentRow.mapping}`}
          align={row[titleContentRow.mapping]?.align || 'center'}
          style={{
            whiteSpace: 'normal',
            wordWrap: 'anywhere',
          }}
        >
          {titleContentRow.mapping !== 'secret' && row[titleContentRow.mapping]}
          {titleContentRow.obfuscated && '*****'}
          {titleContentRow.secretSize > 0 && <ReadMore>{row.secret}</ReadMore>}
        </TableCell>
      ))}
    </TableRow>
  );
};

TableViewRow.propTypes = {
  tableName: PropTypes.string.isRequired,
  tableTitles: PropTypes.instanceOf(Array).isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
  rowIdentifier: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
  selectable: PropTypes.bool.isRequired,
};

export default TableViewRow;
