/* eslint-disable no-console */
import { useContext, useState } from 'react';

import { LanguageContext } from 'context';
import { uploadPublicKey } from 'services';

export const useUpdateUserKey = () => {
  const [publicKeyFile, setPublicKeyFile] = useState(null);
  const [error, setError] = useState(false);
  const [alertText, setAlertText] = useState('');

  const { lang } = useContext(LanguageContext);

  const publicKeyFileHandler = (e) => {
    setPublicKeyFile(e.target?.files[0]);
  };

  /**
   * i18n labels
   */

  const updateUserKeyInfo = {
    infoText: lang?.updatePublicKey.info,
    selectedFileLabel: lang?.updatePublicKey.publicKeyFileInput,
    submitText: lang?.updatePublicKey.submit,
    successAlertText: lang?.updatePublicKey.successAlert,
    errorAlertText: lang?.updatePublicKey.errorAlert,
  };

  const uploadUserPublicKey = async (onUploadProgress) => {
    await uploadPublicKey(publicKeyFile, onUploadProgress, () => {
      setPublicKeyFile(null);
    }).then((res) => {
      if (!res) {
        setAlertText(updateUserKeyInfo.errorAlertText);
        setError(true);
      } else {
        setAlertText(updateUserKeyInfo.successAlertText);
        setError(false);
      }
    });
  };

  return {
    info: updateUserKeyInfo,
    publicKeyFile,
    publicKeyFileHandler,
    uploadUserPublicKey,
    error,
    alertText,
  };
};

export default useUpdateUserKey;
