import { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { LANG_EN, LANG_ES } from 'assets';

export const LanguageContext = createContext({
  lang: {},
  setLang: () => {},
});

export const LanguageProvider = ({ children }) => {
  const langFromLocalStorage = localStorage.getItem('lang');
  const [lang, setLang] = useState(langFromLocalStorage === 'es' ? LANG_ES : LANG_EN);

  useEffect(() => {
    setLang(langFromLocalStorage === 'es' ? LANG_ES : LANG_EN);
  }, [langFromLocalStorage]);

  const onLanguageChange = (langSelected) => {
    setLang(langSelected === 'es' ? LANG_ES : LANG_EN);
    localStorage.setItem('lang', langSelected);
  };

  const providerValue = useMemo(() => ({ lang, setLang: onLanguageChange }), [lang]);

  return <LanguageContext.Provider value={providerValue}>{children}</LanguageContext.Provider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
