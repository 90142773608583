import Grid from '@mui/material/Grid';

import { SecretsTable } from 'components/sections';

export const SecretsSharedWithMe = () => {
  return (
    <Grid container spacing={0.5} item xs={12} direction="column">
      <Grid item>
        <SecretsTable />
      </Grid>
    </Grid>
  );
};

export default SecretsSharedWithMe;
