export const LANG_ES = {
  siteTitle: 'Secure Store',
  login: {
    buttonText: 'Iniciar sesión',
  },
  logout: {
    buttonText: 'Cerrar sesión',
  },
  session: {
    title: 'Sesión Expirada',
    text: 'Tu sesión ha expirado. Por favor vuelve a iniciar sesión',
  },
  navBar: {
    language: 'Idioma',
    spanish: 'Español',
    english: 'Inglés',
    accountSettings: 'Configuraciones de la Cuenta',
  },
  menu: {
    generateKeyPair: 'Generar Llaves',
    shareSecrets: 'Compartir Secretos',
    updatePublicKey: 'Actualizar Llave Pública',
  },
  generateKeyPair: {
    title: 'Generar llaves',
    info: 'Por favor mantén segura tu llave privada; la usarás para desencriptar tus secretos. Si pierdes tu llave privada, perderás el acceso a tus secretos!',
    description: 'Selecciona el tamaño apropiado de la llave para tu secreto',
    KeyPairDescription: 'Ingrese un nombre adecuado para su par de llaves',
    keyPairName: 'Nombre del par de llaves',
    advice: 'Sugerencia: Para secretos grandes las llaves de mayor tamaño son apropiadas',
    adviceNameKeyPair: 'Tip: Establezca un nombre fácil de recordar',
    keyLength: 'Tamaño de la Llave',
    submit: 'Enviar y Descargar',
    privateKey: 'Llave Privada',
    publicKey: 'Llave Pública',
    successAlert: 'Par de llaves generado correctamente',
    dialog: {
      title: '¿Estás seguro?',
      description:
        '¿Estás seguro de guardar un nuevo par de llaves? Recuerda que si guardas unas nuevas no podrás desencriptar secretos enviados o recibidos anteriormente, solo los nuevos.',
      ok: 'Si, estoy seguro',
      cancel: 'No, cancelar',
    },
    infoDownloadKeyPair: 'Por favor, habilite las descargas automáticas de su navegador para descargar su par de llaves',
  },
  deleteSecrets: {
    dialog: {
      title: '¿Estás seguro?',
      description:
        '¿Estás seguro que quieres eliminar los secretos seleccionados? No podrás verlos más, pero la persona que los recibió o envió podrá seguirlos viendo.',
      ok: 'Sí, estoy seguro',
      cancel: 'No, cancelar',
    },
    snackbarAlert: {
      description: 'Secretos eliminados correctamente',
    },
  },
  shareSecrets: {
    recipient: 'Recipiente',
    secretName: 'Nombre del Secreto',
    secretValue: 'Valor del Secreto',
    secretInfo: {
      title: 'Información del Secreto',
      recipientHelper: 'Ingresa el correo de quién debe recibir el secreto',
      recipientInfo: 'El usuario debe tener guardada una llave pública en la aplicación para ser visible en la lista',
      secretNameHelper: 'Valor único para identificar tu secreto',
      secretValueHelper: 'El contenido actual del secreto que quieres enviar',
      addSecret: 'Agregar secreto',
    },
    secretsReadyToShare: {
      title: 'Secretos Listos Para Compartir',
      shareSecrets: 'Compartir Secretos',
    },
    secretsSharedWithMe: {
      title: 'Secretos Compartidos Conmigo',
      privateKeyFileInput: 'Seleccionar archivo de llave privada',
      privateKeyFileInputHelper: 'Debería tener una extensión .p8',
      yourFile: 'Tu archivo',
      decryptSecrets: 'Desencriptar secretos',
      hideSecrets: 'Ocultar secretos',
      table: {
        identifier: 'Identificador',
        time: 'Hora',
        senderEmail: 'Correo del Remitente',
      },
    },
    secretsSharedByMe: {
      title: 'Secretos Compartidos Por Mi',
      table: {
        recipientEmail: 'Correo del Receptor',
      },
    },
    table: {
      pagination: {
        rowsPerPage: 'Filas por página',
        countOf: 'de',
      },
      selectedLabel: {
        one: 'seleccionado',
        moreThanOne: 'seleccionados',
      },
      deleteLabel: 'Eliminar',
    },
    errorAlert: 'Hubo un error mientras se compartían tus secretos',
    successAlert: 'Secretos compartidos correctamente',
    wrongKeyError: 'Llave privada incorrecta',
    emptyPublicKey: 'Por favor, vaya a "Generar llaves"',
  },
  updatePublicKey: {
    info: 'Por favor actualiza tu llave pública si quieres proveer tu propio archivo de la llave, en lugar de permitir a la aplicación generar una para ti',
    publicKeyFileInput: 'Seleccione el archivo de la nueva llave pública',
    submit: 'Subir nueva llave pública',
    titleAlert: 'Subir Archivo',
    successAlert: 'Llave pública almacenada correctamente',
    errorAlert: 'Error mientras se almacenaba tu llave pública',
  },
  utils: {
    yourFile: 'Tu archivo',
  },
  tutorial: {
    buttonLabel: 'Tutorial',
    helpers: {
      back: 'Atrás',
      close: 'Cerrar',
      next: 'Siguiente',
      skip: 'Saltar',
      last: 'Final',
      open: 'Abrir',
    },
    steps: {
      1: {
        content: 'Bienvenido al tutorial del Secure Store',
      },
      2: {
        content: 'Primero, vamos a generar un par de llaves',
      },
      3: {
        content: 'Asigna un nombre a tu nuevo par de llaves',
      },
      4: {
        content: 'Genera tu nuevo par de llaves',
      },
      5: {
        content: 'Envia y descarga tu nuevo par de llaves',
      },
      6: {
        content: 'Vamos a compartir un secreto',
      },
      7: {
        content: 'Selecciona el correo del receptor. Si es tu primera vez te sugerimos seleccionar tu correo',
      },
      8: {
        content: 'Asigna un nombre a tu secreto',
      },
      9: {
        content: 'Ingresa el valor secreto',
      },
      10: {
        content: "Agrega tu secreto a la lista de 'secretos listos para compartir'",
      },
      11: {
        content: 'Ahora es tiempo de compartir tu secreto',
      },
      12: {
        content: 'En esta sección encontrarás los secretos que compartiste con otros usuarios',
      },
      13: {
        content: 'En esta sección encontrarás los secretos que se han compartido contigo y tus secretos personales',
      },
      14: {
        title: 'Vamos a desencriptar secretos!',
        content: 'Selecciona el archivo de la llave privada que descargaste',
      },
      15: {
        content: 'Desencripta los secretos',
      },
      16: {
        content: 'Ahora seleccionemos un secreto',
      },
      17: {
        title: 'Este icono se usa para eliminar secretos',
        content: 'Haga click en siguiente',
      },
      18: {
        content: 'Tutorial completado!',
      },
    },
  },
  colorMode: {
    title: 'Modo de Color',
    dark: 'Oscuro',
    light: 'Claro',
  },
};

export default LANG_ES;
