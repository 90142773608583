import { useContext, useState } from 'react';

import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { LanguageContext } from 'context';
import { fieldSetterHandler } from 'utils';

export const useSecretInfoForm = (buttonClicked, clearAfterSubmit) => {
  const [secretName, setSecretName] = useState('');
  const [secretValue, setSecretValue] = useState('');
  const [recipient, setRecipient] = useState();

  const { lang } = useContext(LanguageContext);

  const isFilled = () => secretName && secretValue && recipient;

  const StyledTooltip = styled(({ className, ...props }) => <Tooltip sx={{ backgroundColor: 'primary' }} {...props} classes={{ popper: className }} />)`
    & .MuiTooltip-tooltip {
      font-size: 12px;
      color: white;
    }
  `;

  const onSubmit = (e) => {
    buttonClicked(e, secretName, secretValue, recipient);
    if (clearAfterSubmit) {
      setSecretName('');
      setSecretValue('');
    }
  };

  /**
   * Form Info
   */

  const formContent = {
    recipientLabel: lang?.shareSecrets.recipient,
    recipientHelperText: lang?.shareSecrets.secretInfo.recipientHelper,
    secretNameLabel: lang?.shareSecrets.secretName,
    secretNameHelperText: lang?.shareSecrets.secretInfo.secretNameHelper,
    secretValueLabel: lang?.shareSecrets.secretValue,
    secretValueHelperText: lang?.shareSecrets.secretInfo.secretValueHelper,
  };

  return {
    secretName,
    secretValue,
    handleRecipient: fieldSetterHandler(setRecipient),
    handleSecretName: fieldSetterHandler(setSecretName),
    handleSecretValue: fieldSetterHandler(setSecretValue),
    formContent,
    onSubmit,
    isFilled,
    StyledTooltip,
  };
};

export default useSecretInfoForm;
