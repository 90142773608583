import axios from 'axios';
import { EnvManager } from 'config';
import { deriveSharedHKDFKey, generateECDHKeys } from '../crypto/ecdhFunctions';
import { encryptWithAesCbc, getInitializationVector } from '../crypto/aesFunctions';

const baseUrl = `${EnvManager.BACKEND_URL}/secrets`;

export const getCurrentUserSecrets = async () => {
  const response = await axios.get(`${baseUrl}/user/current`, {
    withCredentials: true,
  });
  return response?.data;
};

export const shareSecrets = async (secretList, sharedSecret) => {
  const secrets = new Map();

  const encryptionHelper = async (name, value) => {
    const iv = getInitializationVector();
    return {
      name,
      iv: Buffer.from(iv).toString('hex'),
      value: await encryptWithAesCbc(sharedSecret, iv, value),
    };
  };

  await Promise.all(
    secretList.map(async (secretFromList) => {
      const { iv, value, name } = await encryptionHelper(secretFromList.name, secretFromList.value);
      if (!secrets.has(secretFromList.recipient)) {
        secrets.set(secretFromList.recipient, []);
      }
      secrets.get(secretFromList.recipient).push({
        name,
        iv,
        value,
      });
    }),
  );

  const response = await axios.post(
    `${baseUrl}/share`,
    {
      secrets: Object.fromEntries(secrets),
    },
    { withCredentials: true },
  );
  return response?.data;
};

export const performKeyAgreement = async () => {
  const clientKeyPair = await generateECDHKeys();

  const response = await axios.post(
    `${baseUrl}/agree/key`,
    {
      public_key: clientKeyPair.publicKey,
    },
    { withCredentials: true },
  );

  const serverPublicKey = response?.data?.server_public_key;

  return deriveSharedHKDFKey(serverPublicKey, clientKeyPair.privateKey);
};

export const deleteSecrets = async (secrets) => {
  const response = await axios.delete(baseUrl, {
    data: {
      secret_ids: secrets,
    },
    withCredentials: true,
  });

  return response?.data;
};
