import { useContext } from 'react';

import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';

import { LANG_EN } from 'assets';
import { LanguageContext } from 'context';

import { IconMapping } from 'components/mappings';
import { TooltipMenu } from './TooltipMenu';

export const LanguageSelector = () => {
  const Icon = IconMapping.LanguageIcon;

  const { lang, setLang } = useContext(LanguageContext);

  const tooltipMenuItems = [
    {
      title: lang?.navBar.spanish,
      onClick: () => {
        setLang('es');
      },
    },
    {
      title: lang?.navBar.english,
      onClick: () => {
        setLang('en');
      },
    },
  ];

  return (
    <TooltipMenu menuId="tooltip-lang" items={tooltipMenuItems || []} tooltipTitle="Language">
      <ListItemIcon>
        <Icon fontSize="medium" color="inherit" sx={{ mr: 1, mt: 0.5 }} />
        <Typography component="h1" variant="h6" color="white" noWrap sx={{ flexGrow: 1 }}>
          {lang === LANG_EN ? lang?.navBar.english : lang?.navBar.spanish}
        </Typography>
      </ListItemIcon>
    </TooltipMenu>
  );
};

export default LanguageSelector;
