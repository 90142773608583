import { useState, useContext } from 'react';

import { LanguageContext } from 'context';

export const useSecretsShared = () => {
  const [tab, setTab] = useState('1');

  const { lang } = useContext(LanguageContext);

  const handleChangeTab = (_, newTab) => {
    setTab(newTab);
  };

  const labels = {
    tabs: {
      secretsSharedWithMe: lang?.shareSecrets.secretsSharedWithMe.title,
      secretsSharedByMe: lang?.shareSecrets.secretsSharedByMe.title,
    },
  };

  return {
    labels,
    tab,
    handleChangeTab,
  };
};

export default useSecretsShared;
