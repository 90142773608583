import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import { logoutUserSession } from 'services';
import { LanguageContext, TourContext } from 'context';

import { AppBarDrawerCombo, LoadingSection } from 'components/sections';
import { Copyright } from 'components/text';
import { useAuth } from 'hooks';
import { Tutorial } from 'components/tutorial';

export const SecureStorePageLayout = ({ drawerMenuItemsFromProps, tooltipMenuItemsFromProps, children }) => {
  const navigate = useNavigate();
  const { activeUser, isLoading } = useAuth();
  const { lang } = React.useContext(LanguageContext);

  const { handleTour } = React.useContext(TourContext);

  const toggleTutorial = () => {
    handleTour({ run: true, tourActive: true, stepIndex: 0 });
  };

  const tooltipMenuItems = [
    {
      title: lang?.logout.buttonText,
      icon: 'Logout',
      id: 'logout-btn',
      onClick: () =>
        logoutUserSession().then((didLogout) => {
          if (didLogout) {
            navigate('/login');
          }
        }),
    },
  ];

  const drawerMenuItems = [
    {
      text: lang?.menu.generateKeyPair,
      icon: 'LockReset',
      onClick: () => navigate('/generate-user-key'),
      className: 'generate-key-pair-link',
    },
    {
      text: lang?.menu.shareSecrets,
      icon: 'Dashboard',
      onClick: () => navigate('/dashboard'),
      className: 'share-secrets-link',
    },
    {
      text: lang?.menu.updatePublicKey,
      icon: 'Key',
      onClick: () => navigate('/update-user-key'),
    },
    {
      text: lang?.tutorial.buttonLabel,
      icon: 'LocalLibraryIcon',
      onClick: toggleTutorial,
    },
  ];

  return isLoading ? (
    <LoadingSection />
  ) : (
    <Box sx={{ display: 'flex' }}>
      <Tutorial />
      <AppBarDrawerCombo
        drawerMenuItems={drawerMenuItemsFromProps || drawerMenuItems}
        drawerWidth={260}
        tooltipMenuItems={tooltipMenuItemsFromProps || tooltipMenuItems}
        avatarImgSrc={activeUser.picture}
      />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {children}
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
};

SecureStorePageLayout.defaultProps = {
  drawerMenuItemsFromProps: null,
  tooltipMenuItemsFromProps: null,
};

SecureStorePageLayout.propTypes = {
  drawerMenuItemsFromProps: PropTypes.instanceOf(Array),
  tooltipMenuItemsFromProps: PropTypes.instanceOf(Array),
  children: PropTypes.node.isRequired,
};

export default SecureStorePageLayout;
